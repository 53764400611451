import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../button';

import styles from './banner-refferal.module.scss';

const BannerRefferal = ({ showBanner, customerId, firstName, lastName, closeBanner }) =>
  showBanner && (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerWrapper}>
        <section>
          <h4 className={styles.bannerTitle}>
            You are referred by{' '}
            <span className={styles.userName}>{`${firstName} ${lastName}`}</span>
          </h4>

          <div className={styles.userInfo}>
            <div className={styles.userInfoSection}>
              <span className={styles.userInfoLabel}>ID:</span>
              <span className={styles.userInfoText}>{customerId}</span>
            </div>
          </div>
        </section>

        <Button className={styles.bannerBtn} onClick={closeBanner}>
          {useIntl().formatMessage({ id: 'button.gotIt' })}
        </Button>
      </div>
    </div>
  );

BannerRefferal.defaultProps = {
  showBanner: false,
  customerId: '',
  firstName: '',
  lastName: '',
  closeBanner: () => { },
};

BannerRefferal.propTypes = {
  showBanner: PropTypes.bool,
  customerId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  closeBanner: PropTypes.func,
};

export default BannerRefferal;
